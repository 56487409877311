// React
import { useEffect, useState, useMemo, Fragment } from "react"

// Router
import { Routes } from "@/constants/routes"
import { Link } from "@/lib/router"

// Utils
import { DateTime } from "@/lib/dates"
import { motion } from "@/lib/animations"
import { Helmet } from "@/lib/seo"
import { emptyValue } from "@/utils/helpers"

// UI
import { classNames } from "@/lib/classnames"
import { Card, CardBody, CardWrapper } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { TableEmptyState } from "@/components/table-controls/TableEmptyState"
import { Tooltip } from "@/components/Tooltip"

// Translations
import { Trans, useTrans } from "@/i18n"
import { useLang } from "@/context/lang"

// GraphQL
import {
	useInterestPaymentsQuery,
	PayOutState,
	InterestPaymentType,
	PaymentEntryType,
	ProjectTypeEnum,
	PaymentStateEnum,
} from "@/api/graphql"

// Hooks
import { useCurrentProjectId } from "../hooks/useCurrentProjectId"
import { useCurrentProject } from "../hooks"

// Dates
import { dateFormat } from "@/constants/constants"

// Tables
import {
	CellProps,
	useGlobalFilter,
	usePagination,
	useSortBy,
	useTable,
	UseTableOptions,
} from "react-table"
import {
	TableBody,
	TableDataCell,
	TableHead,
	TableRowCell,
	TableHeading,
} from "@/components/table-controls/TableItems"

// Icons
import { FiInfo } from "@/lib/icons"

/**
 * Extend PaymentEntryType with calculated fields
 * TODO: These can be provided by the backend
 */
export type InterestPaymentTypeExtended = InterestPaymentType & {
	payment_deadline_date: DateTime
	payment_sum: number
	interest_period_end_date: DateTime
	payment_entry: PaymentEntryType
}

/**
 * ProjectRevenue page
 * @returns
 */
export const ProjectRevenue = () => {
	const { formatNumber, formatCurrency } = useLang()
	const t = useTrans(["project", "common"])
	const id = useCurrentProjectId()

	// State
	const [
		interestPaymentHasUnpaidPayments,
		setInterestPaymentHasUnpaidPayments,
	] = useState<boolean>(false)

	// Queries
	const { data: currentProject } = useCurrentProject()
	const { data } = useInterestPaymentsQuery({
		id: String(id),
		project_id: String(id),
	})

	// Determine if we have some invalid payments
	useEffect(() => {
		const hasUnpaidPayments = data?.me?.interest_payments?.some(
			(interestPayment) =>
				interestPayment?.payment_entries?.some(
					(element) => element?.state !== PayOutState.PayoutCompleted, // TODO: Change this logic
				),
		)

		if (hasUnpaidPayments) {
			setInterestPaymentHasUnpaidPayments(true)
		}
	}, [data?.me?.interest_payments])

	// Memo interest_payments
	const interestPayments = useMemo(() => {
		return (
			data?.me?.interest_payments?.map((interestPayment) => {
				const interestPeriodEndDate = DateTime.fromISO(
					interestPayment?.interestperiod?.end,
				)

				const payment = interestPayment?.payment_entries?.find(
					(element) => element !== undefined,
				)

				const paymentSum =
					Number(payment?.cost) +
					Number(payment?.amortization) +
					Number(payment?.repayment)

				// Return payments
				return {
					...interestPayment,
					payment_sum: paymentSum,
					interest_period_end_date: interestPeriodEndDate,
					payment_entry: payment,
				}
			}) || []
		)
	}, [data?.me?.interest_payments])

	// Table Data
	const columns = useMemo(() => {
		const cols: UseTableOptions<InterestPaymentTypeExtended>["columns"] = [
			{
				id: "interest_period",
				accessor: (_row, rowIndex) => rowIndex + 1,
				Header: () => (
					<TableHeading variant="static" className="w-10">
						{t(
							"project:project.revenue.table.headers.interest_period",
						)}
					</TableHeading>
				),
				Cell: ({ value }: { value: string }) => (
					<TableDataCell className="w-10 font-medium">
						{value}
					</TableDataCell>
				),
			},
			{
				id: "transaction_id",
				accessor: (data) => data?.transaction_id,
				Header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.transaction_id",
						)}
					</TableHeading>
				),
				Cell: ({ value }: { value: string }) => (
					<TableDataCell className="font-medium">
						{value}
					</TableDataCell>
				),
			},
			{
				id: "date",
				accessor: (data) => data?.interest_period_end_date,
				Header: () => (
					<TableHeading variant="static">
						{t("project:project.revenue.table.headers.date")}
					</TableHeading>
				),
				Cell: ({ value }: { value: DateTime }) => (
					<TableDataCell>
						{value ? value.toFormat(dateFormat) : emptyValue()}
					</TableDataCell>
				),
			},
			{
				id: "payment_deadline_date",
				accessor: (data) => data,
				Header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.payment_deadline_date",
						)}
					</TableHeading>
				),
				Cell: ({ value }: { value: InterestPaymentTypeExtended }) => {
					return (
						<TableDataCell>
							<span
								className={classNames(
									value.payment_entry.payment_state ===
										PaymentStateEnum.Done &&
										"text-green-500",
									value?.payment_entry.payment_state !==
										PaymentStateEnum.Done &&
										DateTime.fromISO(
											value?.interestperiod
												?.payment_deadline_date,
										) < DateTime.now() &&
										"text-red-500",
								)}
							>
								{DateTime.fromISO(
									value?.interestperiod
										?.payment_deadline_date,
								).toFormat(dateFormat) || emptyValue()}
							</span>
						</TableDataCell>
					)
				},
			},
			{
				id: "payment_entry.state",
				accessor: (data) => data?.payment_entry.state,
				Header: () => (
					<TableHeading>
						{t(
							"project:project.revenue.table.headers.payout_state",
						)}
					</TableHeading>
				),
				Cell: ({ value }: { value: PayOutState }) => (
					<TableDataCell>
						<Tooltip
							content={
								value
									? t(
											`project:project.revenue.payment_entry_states.${value}.tooltip`,
									  )
									: t(
											"project:project.revenue.payment_entry_states.unknown.tooltip",
									  )
							}
						>
							<div className="flex cursor-pointer items-center">
								<FiInfo className="mr-1" />
								{value
									? t(
											`project:project.revenue.payment_entry_states.${value}`,
									  )
									: t(
											`project:project.revenue.payment_entry_states.unknown`,
									  )}
							</div>
						</Tooltip>
					</TableDataCell>
				),
			},
			{
				id: "share_count",
				accessor: (data) => data?.payment_entry?.share_count,
				Header: () => (
					<TableHeading variant="static">
						{currentProject?.project?.type ===
						ProjectTypeEnum.Portfolio
							? t(
									"project:project.revenue.table.headers.solar_bonds",
							  )
							: t(
									"project:project.revenue.table.headers.solar_shares",
							  )}
					</TableHeading>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{value ? formatNumber(value) : emptyValue()}
					</TableDataCell>
				),
			},
			{
				id: "interest",
				accessor: (data) => data?.payment_entry?.cost,
				Header: () => (
					<TableHeading variant="static">
						{t("project:project.revenue.table.headers.interest")}
					</TableHeading>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{value ? formatCurrency(value) : emptyValue()}
					</TableDataCell>
				),
			},
			{
				id: "amortization",
				accessor: (data) => data?.payment_entry?.amortization,
				Header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.amortization",
						)}
					</TableHeading>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{value ? formatCurrency(value) : emptyValue()}
					</TableDataCell>
				),
			},
			{
				id: "compensation",
				accessor: (data) => data?.payment_entry?.repayment,
				Header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.compensation",
						)}
					</TableHeading>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{value ? formatCurrency(value) : emptyValue()}
					</TableDataCell>
				),
			},
			{
				id: "payment",
				accessor: (data) => data?.payment_sum,
				Header: () => (
					<TableHeading variant="static">
						{t("project:project.revenue.table.headers.payment")}
					</TableHeading>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{value ? formatCurrency(value) : emptyValue()}
					</TableDataCell>
				),
			},
			{
				id: "project.amortization_schedule",
				accessor: (data) => data?.project?.amortization_schedule,
				Header: () => (
					<TableHeading variant="static">
						{t(
							"project:project.revenue.table.headers.project_amortization_schedule",
						)}
					</TableHeading>
				),
				Cell: ({ value }: { value: number }) => (
					<TableDataCell>
						{t(
							`common:common.project.amortization_schedule.${value}`,
						)}
					</TableDataCell>
				),
			},
		]
		return cols.filter(Boolean)
	}, [])
	const {
		getTableBodyProps,
		headerGroups,
		page,
		state: { pageSize, pageIndex },
		prepareRow,
	} = useTable(
		{
			columns,
			data: (interestPayments as InterestPaymentTypeExtended[]) || [],
			defaultColumn: {
				Cell: ({
					value,
				}: CellProps<InterestPaymentTypeExtended, string | number>) => (
					<TableDataCell className="break-word whitespace-pre-wrap">
						{value}
					</TableDataCell>
				),
			},
		},
		useGlobalFilter,
		useSortBy,
		usePagination,
	)

	return (
		<>
			<Helmet>
				<title>
					{t("project:project.revenue.page_title", {
						projectName: currentProject?.project?.name,
					})}
				</title>
			</Helmet>
			<div className="space-y-6">
				<Card className="xl:w-[60%]">
					<Heading as="h5" className="mb-3 sm:truncate">
						{t("project:project.revenue.heading")}
					</Heading>
					<p className="mb-2 whitespace-pre-wrap text-sm text-gray-500">
						{t(`project:project.revenue.copy`, {
							projectName: currentProject?.project?.name,
						})}
					</p>

					{/** Bullet points */}
					<ul className="ml-0 list-disc whitespace-pre-wrap text-sm text-gray-500">
						{currentProject?.project?.supplier && (
							<li>
								{t(
									"project:project.revenue.copy.point.supplier",
								)}
							</li>
						)}
						<li>{t(`project:project.revenue.copy.point.date`)}</li>
						<li>
							<Trans
								ns="project"
								i18nKey="project.revenue.cta"
								components={{
									Link: (
										<Link
											to={`${Routes.StatisticsPlatformSingleProject}/${currentProject?.project?.id}`}
											className="text-secondary-300 hover:text-secondary-700 font-medium"
											rel="noreferrer"
										/>
									),
								}}
							/>
						</li>
						{interestPaymentHasUnpaidPayments === true && (
							<li>
								{t(
									`project:project.revenue.copy.point.unpaid-payments`,
								)}
							</li>
						)}
					</ul>
				</Card>
				<CardWrapper className="overflow-hidden border-b border-gray-200">
					<div className="overflow-x-auto overflow-y-hidden">
						<motion.table className="min-w-full divide-y divide-gray-200">
							<TableHead>
								{headerGroups.map((headerGroup) => (
									<tr
										{...headerGroup.getHeaderGroupProps()}
										role="row"
									>
										{headerGroup.headers.map((column) => {
											const { key } =
												column.getHeaderProps()

											return (
												<Fragment key={key}>
													{column.render("Header")}
												</Fragment>
											)
										})}
									</tr>
								))}
							</TableHead>
							<TableBody
								role="rowgroup"
								{...getTableBodyProps()}
								data-testid="tablebody-overview"
								data-pageindex={pageIndex}
							>
								{page.map((row) => {
									prepareRow(row)

									return (
										<TableRowCell
											key={row.id}
											isOdd={row.index % 2 === 0}
											data-testid={`item-${row.original.interest_period_end_date.year}${row.index}`}
										>
											{row.cells.map((cell) => {
												return (
													<Fragment
														key={cell.column.id}
													>
														{cell.render("Cell")}
													</Fragment>
												)
											})}
										</TableRowCell>
									)
								})}
								{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
								{page.length < pageSize && pageIndex !== 0 ? (
									<>
										{Array(
											Math.max(pageSize - page.length, 1),
										)
											.fill(true)
											.map((_, index) => (
												<TableRowCell
													key={index}
													withHover={false}
													isOdd={index % 2 === 0}
												>
													<TableDataCell
														colSpan={columns.length}
													>
														<span className="dummy-text" />
													</TableDataCell>
												</TableRowCell>
											))}
									</>
								) : null}
							</TableBody>
						</motion.table>
					</div>
					{data?.me?.interest_payments?.length === 0 && (
						<CardBody>
							<TableEmptyState>
								{t(
									"project:project.revenue.table.empty_state",
									{
										date: DateTime.fromISO(
											currentProject?.project
												?.current_interest_period?.end,
										).toFormat(dateFormat),
									},
								)}
							</TableEmptyState>
						</CardBody>
					)}
				</CardWrapper>
			</div>
		</>
	)
}
