// React
import { Fragment, useMemo, useState } from "react"
import { useNavigate } from "@/lib/router"

import { Helmet } from "@/lib/seo"
import { useTrans } from "@/i18n"

import { FiChevronDown } from "@/lib/icons"

// Queries
import { useMessagesQuery, MessageType } from "@/api/graphql"

// DateTime
import { DateTime } from "@/lib/dates"
import { dateFormat } from "@/constants/constants"

// Tables
import {
	getCoreRowModel,
	getExpandedRowModel,
	getFilteredRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	useReactTable,
	flexRender,
	CellContext,
} from "@/lib/table"
import {
	Table,
	TableBody,
	TableDataCell,
	TableRowCell,
} from "@/components/table-controls/TableItems"

// UI
import { CardBody, CardWrapper, Card } from "@/components/Card"
import { Heading } from "@/components/Typography"
import { SearchInput } from "@/components/form-controls/Input"
import { Button } from "@/components/Button"
import { PaginationAsButtons } from "@/components/PaginationAsButtons"

// TODO: Remove this
const MessageStateEnum = {
	Unread: "UNREAD",
	Archived: "ARCHIVED",
	Trashed: "TRASHED",
} as const

/**
 * AllCommunications
 * @returns
 */
export const AllCommunications = () => {
	// State
	const [filter, setFilter] = useState<string | undefined>()
	const [sort, setSort] = useState<string | undefined>()
	const limit = 10
	const [currentPage, setCurrentPage] = useState<number>(0)

	// Translations
	const t = useTrans(["project", "common"])

	// Query
	const { data } = useMessagesQuery({
		count: limit,
		offset: currentPage * limit,
		subject: filter,
	})

	// Memo
	const messages = useMemo(() => {
		return (
			(data?.me?.messages?.list?.results?.filter(
				Boolean,
			) as MessageType[]) || []
		)
	}, [data?.me?.messages?.list?.results])

	// PaginatorInfo
	const navigate = useNavigate()
	const totalMessagesCount = useMemo(
		() => data?.me?.messages?.list?.total_count ?? 0,
		[data?.me?.messages?.list?.total_count],
	)

	// Table configuration
	const columns = useMemo(
		() => [
			{
				id: "subject",
				accessorKey: "subject",
				cell: (info: CellContext<MessageType, string>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			},
			{
				id: "received_at",
				accessorFn: (row: MessageType) =>
					row?.received_at
						? DateTime.fromISO(row.received_at).toFormat(dateFormat)
						: "",
				cell: (info: CellContext<MessageType, string>) => (
					<TableDataCell>{info.getValue()}</TableDataCell>
				),
			},
		],
		[],
	)

	// Table instance
	const table = useReactTable({
		data: messages,
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getExpandedRowModel: getExpandedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
	})

	return (
		<>
			<Helmet>
				<title>{t("project:project.communications.heading")}</title>
			</Helmet>
			<CardWrapper>
				<CardBody>
					<div className="md:flex">
						<Heading as="h5" className="mb-3 sm:truncate">
							{t("project:project.communications.heading")}
						</Heading>
						<div className="mb-4 ml-auto flex gap-4">
							<div className="sm:ml-auto">
								<SearchInput
									disabled // TODO: Re-enable when we can search on partial words as well
									onChange={(event) => {
										if (
											event.currentTarget.value.charAt(
												event.currentTarget.value
													.length - 1,
											) === " "
										) {
											setFilter(event.currentTarget.value)
										}
									}}
									label={"Zoeken"}
									className="md:width-auto min-w-full"
								/>
							</div>

							{/** Filter on project status */}
							<div className="relative">
								<label htmlFor="projectStatus">
									<Button
										size="small"
										variant="transparent"
										disabled
									>
										{sort === undefined
											? "Sorteren"
											: t(
													`common:common.project.status.${sort}`,
											  )}
										<FiChevronDown className={"ml-2"} />
										<select
											disabled
											name="projectStatus"
											className="h-100 absolute left-0 top-0 w-full cursor-pointer opacity-0"
											onChange={(evt) => {
												setSort(evt.currentTarget.value)
											}}
											value={sort ?? undefined}
										>
											<option value={undefined}>
												Sorteren
											</option>
											{Object.entries(
												MessageStateEnum,
											).map(([_key, value]) => (
												<option
													value={value}
													key={value}
												>
													{t(
														`common:common.project.status.${value}`,
													)}
												</option>
											))}
										</select>
									</Button>
								</label>
							</div>
						</div>
					</div>

					{/** When user has no messages */}
					{totalMessagesCount === 0 && (
						<Card>
							<p className="text-sm text-gray-500">
								<div className="z-2 relative px-8">
									<Heading as="h4" className="text-center">
										{t(
											"project:project.communications.no-data.title",
										)}
									</Heading>
									<p className="mt-2 text-center text-sm text-gray-500">
										{t(
											"project:project.communications.no-data.copy",
										)}
									</p>
								</div>
							</p>
						</Card>
					)}

					<Table
						className="min-w-[64rem] lg:min-w-0"
						data-testid="tablebody"
					>
						<TableBody
							data-testid="tablebody-overview"
							data-page-index={
								table.getState().pagination.pageIndex
							}
						>
							{table.getRowModel().rows.map((row) => (
								<Fragment key={row.id}>
									<TableRowCell
										isOdd={row.index % 2 === 0}
										className={"cursor-pointer"}
										onClick={() =>
											navigate(
												`${row.original.message_id}`,
											)
										}
									>
										{row
											.getAllCells()
											.map((cell, index) => {
												return (
													<Fragment key={index}>
														{flexRender(
															cell.column
																.columnDef.cell,
															cell.getContext(),
														)}
													</Fragment>
												)
											})}
									</TableRowCell>
								</Fragment>
							))}

							{/* Pads the last entries in the table so the table doesn't collapse in the UI */}
							{table.getRowModel().rows.length <
								table.getState().pagination.pageSize &&
							table.getState().pagination.pageIndex !== 0 ? (
								<>
									{Array(
										Math.max(
											table.getState().pagination
												.pageSize -
												table.getRowModel().rows.length,
											1,
										),
									)
										.fill(true)
										.map((_, index) => (
											<TableRowCell
												key={index}
												withHover={false}
												isOdd={index % 2 === 0}
											>
												<TableDataCell
													colSpan={columns.length}
												>
													<span className="dummy-text" />
												</TableDataCell>
											</TableRowCell>
										))}
								</>
							) : null}
						</TableBody>
					</Table>
					<CardBody>
						{messages?.length !== 0 && (
							<PaginationAsButtons
								countPerPage={limit}
								totalCount={totalMessagesCount ?? 0}
								itemType={
									"common.pagination.item_types.message"
								}
								currentPage={currentPage + 1}
								currentItemsAmount={messages?.length ?? 0}
								onNextPage={() =>
									setCurrentPage(
										(currentPage) => currentPage + 1,
									)
								}
								onPrevPage={() =>
									setCurrentPage((currentPage) =>
										Math.max(currentPage - 1, 0),
									)
								}
								analyticsId="investments"
							/>
						)}
					</CardBody>
				</CardBody>
			</CardWrapper>
		</>
	)
}
