// React
import { useMemo, ReactNode, useState } from "react"

// Translations
import { useTrans } from "@/i18n"

// Dates
import { DateTime } from "@/lib/dates"

// GraphQL
import {
	useProjectMonthlyProductionTableQuery,
	ProjectType,
} from "@/api/graphql"

// CSV
import { exportToCsv, getCsvFileName } from "@/lib/csv"

// UI
import {
	TableBody,
	TableDataCell,
	TableHead,
	TableHeading,
	TableRowCell,
} from "@/components/table-controls/TableItems"
import { PillInverted } from "@/components/Pill"
import { Heading } from "@/components/Typography"
import { DownloadSelector } from "@/components/DownloadSelector"
import { DownloadableFileType } from "@/components/DownloadSelector/types"

// Types
type Columns<ProjectType> = Array<{
	title: string
	accessor: (data: ProjectType) => ReactNode
}>

/**
 * ProjectMonthlyProductionTable
 * @returns
 */
interface ProjectMonthlyProductionTableProps {
	startTime: DateTime
	endTime: DateTime
}
export function ProjectMonthlyProductionTable({
	startTime,
	endTime,
}: ProjectMonthlyProductionTableProps) {
	const t = useTrans(["investments", "common"])

	// GraphQL
	const { data } = useProjectMonthlyProductionTableQuery({
		startTime: startTime?.toJSDate(),
		endTime: endTime?.toJSDate(),
	})

	// Table
	const [rows, setRows] = useState<ProjectType[]>([])

	useMemo(() => {
		const results = data?.me?.investment_projects?.results as
			| ProjectType[]
			| undefined
		setRows(results ?? [])
	}, [data?.me?.investment_projects])

	const columns: Columns<ProjectType> = [
		{
			title: t(
				"investments:investments.monthly_overview.block.project-production-table.headers.project_name",
			),
			accessor: (data) => data.name,
		},
		{
			title: t(
				"investments:investments.monthly_overview.block.project-production-table.headers.solar_shares",
			),
			accessor: (data) => data.investor_shares_value_stats?.total_shares,
		},
		{
			title: t(
				"investments:investments.monthly_overview.block.project-production-table.headers.production_kwh",
			),
			accessor: (data) =>
				data.investor_production_stats
					?.investor_generated_power_in_kwh !== "0" ? ( // Decimals are returned from API as strings
					<>
						{`${parseFloat(
							data.investor_production_stats
								?.investor_generated_power_in_kwh || "0",
						).toFixed(1)} kWh`}
					</>
				) : (
					<>
						{data?.state ? (
							<PillInverted variant={data?.state}>
								{t(
									`common:common.project.status.${data?.state}`,
								)}
							</PillInverted>
						) : null}
					</>
				),
		},
		{
			title: t(
				"investments:investments.monthly_overview.block.project-production-table.headers.production_percentage",
			),
			accessor: (data) =>
				data.investor_production_stats?.total_production_percentage !==
				"0.00" // Decimals are returned from API as strings
					? `${data.investor_production_stats?.total_production_percentage}%`
					: null,
		},
		{
			title: t(
				"investments:investments.monthly_overview.block.project-production-table.headers.production_co2",
			),
			accessor: (data) =>
				data.investor_production_stats?.investor_co2_saving !== "0.0000" // Decimals are returned from API as strings
					? `${(
							parseFloat(
								data.investor_production_stats
									?.investor_co2_saving || "0",
							) / 1000
					  ).toFixed(1)} kg`
					: null,
		},
	]

	/**
	 * handleDownload
	 * @param fileType
	 */
	const handleDownload = (fileType: DownloadableFileType) => {
		if (fileType === DownloadableFileType.Csv) {
			exportToCsv({
				filename: getCsvFileName(
					t(
						"investments:investments.monthly_overview.block.project-production-table.title",
						{
							date: startTime.toLocaleString({
								month: "long",
								year: "numeric",
							}),
						},
					),
				),
				data: rows?.map((project) => ({
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.project_name",
					)]: project.name,
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.solar_shares",
					)]: project.investor_shares_value_stats?.total_shares || "",
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_kwh",
					)]: project.investor_production_stats
						?.investor_generated_power_in_kwh
						? parseFloat(
								project.investor_production_stats
									.investor_generated_power_in_kwh,
						  ).toFixed(1)
						: "",
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_percentage",
					)]:
						project.investor_production_stats
							?.total_production_percentage !== "0.00"
							? project.investor_production_stats
									?.total_production_percentage
							: "",
					[t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_co2",
					)]:
						project.investor_production_stats
							?.investor_co2_saving !== "0.0000"
							? (
									parseFloat(
										project.investor_production_stats
											?.investor_co2_saving || "0",
									) / 1000
							  ).toFixed(1)
							: "",
				})),
				headers: [
					// Create CSV headers using the same translations as the table
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.project_name",
					),
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.solar_shares",
					),
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_kwh",
					),
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_percentage",
					),
					t(
						"investments:investments.monthly_overview.block.project-production-table.headers.production_co2",
					),
				],
			})
		}
	}

	return (
		<>
			<div className="flex items-center justify-between">
				<Heading as="h2" styleAs="h5" className="mb-4">
					{t(
						"investments:investments.monthly_overview.block.project-production-table.title",
						{
							date: startTime.toLocaleString({
								month: "long",
								year: "numeric",
							}),
						},
					)}
				</Heading>
				<div className="ml-auto">
					<DownloadSelector
						options={[
							{
								name: t("common:button.download.csv"),
								value: DownloadableFileType.Csv,
							},
						]}
						onDownload={handleDownload}
					/>
				</div>
			</div>

			{/** Using default HTML table instead of TableItems to prevent scrollbar */}
			<table className="w-full">
				{/* table header */}
				<TableHead>
					<tr role="row">
						{columns.map((header) => {
							return (
								<TableHeading
									as="th"
									key={header.title}
									colSpan={1}
									role="columnheader"
									title={header.title}
								>
									{header.title}
								</TableHeading>
							)
						})}
					</tr>
				</TableHead>
				{/* table body and table cells */}
				<TableBody role="rowgroup" data-testid="tablebody">
					{rows?.map(({ ...row }, index) => {
						return (
							<TableRowCell
								key={index}
								isOdd={index % 2 === 0}
								role="row"
							>
								{columns.map((column) => {
									return (
										<TableDataCell
											key={column.title}
											className="break-word whitespace-pre-wrap"
										>
											{column.accessor(row)}
										</TableDataCell>
									)
								})}
							</TableRowCell>
						)
					})}
				</TableBody>
				{rows && rows.length > 0 ? (
					<tfoot>
						<tr>
							{columns.map((column) => {
								return <TableDataCell key={column.title} />
							})}
						</tr>
					</tfoot>
				) : null}
			</table>
		</>
	)
}
