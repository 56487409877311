import jsonToCsvExport from "json-to-csv-export"

/**
 * exportToCsv
 * @param param0
 * @returns
 */
export const exportToCsv = ({
	data,
	filename,
	headers,
}: {
	data: any
	filename: string
	headers: string[]
}) => {
	const delimiter = ";" // This allows for default parsing in Excel
	const csv = jsonToCsvExport({ data, filename, delimiter, headers })
	return csv
}

export function getCsvFileName(title: string) {
	return `Zonhub - ${title}.csv`
}
